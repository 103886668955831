import React, { useState, useEffect, useRef } from 'react'
import '../assets/css/style.css';

export default function VideoIntro() {
  const vidRef = useRef()

  useEffect(() => {
    console.log("video play", vidRef.current)
    vidRef.current && vidRef.current.play()
  }, [])

  const endedVideo = () => {
    console.log("endedVideo")
      window.location.href = '/#/Minting'
  }
  return (
    <div className="main-container al-v">
      <video
          ref={vidRef}
          muted
          width="100%"
          height="100%"
          autoPlay
          onEnded={endedVideo}
          className="videoIntro"
      >
          <source src="/video/videointro.mp4" type="video/mp4" />
      </video>
      <div className="video-skip-section">
          <a href='/#/Minting' className="video-skip-btn">SKIP</a>
      </div>
    </div>
  );
}
