import { useWallet } from "use-wallet";
import MintingCard from "components/common/MintingCard";

export default function NFTMintingPage() {
  const wallet = useWallet();
  return (
    <div className="main-container al-v">
      {wallet.isConnected() !== true ? (
        <h1 style={{ textAlign: "center" }}>Wallet not connected.</h1>
      ) : (
        <MintingCard></MintingCard>
      )}
    </div>
  );
}
